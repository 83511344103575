<template>
  <div class="user">
    <loading :loading="timesSpining"></loading>
    <div class="study_header">
      <div class="study_header_name">{{ userData.realname }}</div>
      <div class="study_header_day">这是你加入学习的第 {{ day }} 天</div>
    </div>
    <div class="container margin">
      <side-nav>
        <div class="user__content">
          <div class="certificate_list" v-if="data.length > 0">
            <div
              class="certificate_item"
              v-for="(item, index) in data"
              :key="index"
            >
              <div class="item_img">
                <img
                  :src="getFileAccessHttpUrl(item.certificatePath)"
                  alt=""
                />
              </div>
              <div class="item_cent">
                <div class="item_cent_ic"></div>
                <div class="item_cent_text">
                  <div class="item_cent_name">{{ item.certificateName }}</div>
                  <div class="item_cent_time">{{ item.createTime }}</div>
                </div>
                <div
                  class="item_cent_check"
                >
                  查看
                </div>
              </div>
              <div class="position_img">
                <viewer :images="item.imageList">
                  <!-- photo 一定要一个数组，否则报错 -->
                  <img
                    v-for="(src, kIndex) in item.imageList"
                    :key="kIndex"
                    :src="getFileAccessHttpUrl(src)"
                  />
                </viewer>
              </div>
            </div>
          </div>
          <!-- <div class="certificate_noData" v-else>
            <a-empty />
          </div> -->
        </div>
      </side-nav>
    </div>
  </div>
</template>

<script>
import { getFileAccessHttpUrl } from "@/utils/tools";
import { getCertificateList } from "@/api/user.js";
import loading from "@/components/loading";
import { getUserData } from "@/api/user";
import sideNav from "@/components/side-nav";
export default {
  components: { sideNav, loading },
  data() {
    return {
      timesSpining: false,
      data: [],
      userData: {},
      day: 0,
    };
  },
  computed: {
    getFileAccessHttpUrl() {
      return (avatar) => {
        return getFileAccessHttpUrl(avatar);
      };
    },
  },
  created() {
    this.getUserData();
    this.getData();
  },
  methods: {
    getData() {
      this.timesSpining = true;
      getCertificateList()
        .then((res) => {
          
          if (res.code === 200) {
            this.data = res.result;
            for (let i = 0; i < this.data.length; i++) {
              this.data[i].imageList = [];
              this.data[i].imageList.push(this.data[i].certificatePath);
            }
          } else {
            this.$message.error(res.message);
          }
        })
        .finally(() => {
          this.timesSpining = false;
          console.log("看下数据");
        });
    },
    getUserData() {
      getUserData().then((res) => {
        console.log(res);
        this.userData = res.result;
        this.setDate(this.userData.createTime);
        console.log(res.result.studyDurationText, "学习时长");
        if (res.result.studyDurationText === "00分") {
          this.userData.studyDurationText = "0分";
        }
        this.depart = this.userData.departList.join();
        if (this.userData.positionList !== null) {
          this.position = this.userData.positionList.join();
        }
      });
    },
    // 处理时间
    setDate(date) {
      var dateArr = date.split(" ")[0];
      var time = new Date(dateArr).getTime() / 1000; // 创建时间的时间戳
      var today = new Date().getTime() / 1000; // 当前时间的时间戳
      var daySeconds = 3600 * 24; // 一天的秒数
      this.day = parseInt((today - time) / daySeconds);
      // console.log(this.day)
    },
  },
};
</script>

<style lang = "scss" scoped>
.user {
  .study_header {
    width: 1200px;
    height: 100px;
    background: #5db4ff;
    box-shadow: 0px 5px 10px 0px #a7d6ff;
    border-radius: 15px;
    margin: 48px auto 24px;
    overflow: hidden;
    .study_header_name {
      font-size: 30px;
      font-family: MicrosoftYaHei, MicrosoftYaHei;
      font-weight: bold;
      color: #ffffff;
      line-height: 30px;
      margin-left: 48px;
      margin-top: 20px;
    }
    .study_header_day {
      font-size: 18px;
      font-family: MicrosoftYaHei;
      color: #ffffff;
      line-height: 18px;
      margin-left: 48px;
      margin-top: 16px;
    }
  }
  &__title {
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 16px;
  }
  .user__content {
    width: 100%;
  }
}
.certificate_list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  .certificate_item {
    width: 265px;
    background: #ffffff;
    box-shadow: 0px 6px 5px 0px rgba(0, 0, 0, 0.05);
    margin: 0 23px 24px 0;
    position: relative;
    .item_img {
      width: 265px;
      background: #bfbfbf;
      height:250px;
      overflow:hidden;
      img{
        width: 100%;
        height: auto;
      }
    }
    .item_cent {
      display: flex;
      align-items: center;
      padding: 0 16px;
      margin:14px 0;
      .item_cent_ic {
        width: 3px;
        height: 35px;
        background: linear-gradient(0deg, #d1d9ff 0%, #cadeff 100%);
      }
      .item_cent_text {
        flex: 1;
        margin: 0 7px;
        .item_cent_name {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #595959;
          line-height: 16px;
          margin-bottom: 5px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2; /* 设置最大显示行数 */
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
        }
        .item_cent_time {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #adadad;
          line-height: 14px;
        }
      }
      .item_cent_check {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #5db4ff;
      }
    }
    .position_img {
      position: absolute;
      z-index: 999;
      height: 100%;
      width: 100%;
      background: #5db4ff;
      top: 0;
      opacity: 0;
      div {
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
.certificate_noData {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px 0;
}
.qsy_img {
  width: 100%;
  height: 100%;
  display: block;
}
</style>
